import React from "react"
import styled from 'styled-components';
import Layout from "../layouts";
import Project from "../components/project";
import Hover from "../components/hover";
import Footer from "../components/footer";
import VideoBackground from "../components/videoBackground";
import ArrowDown from "../components/arrowDown";
import AnimatedText from '../components/animatedText';
import Seo from "../components/seo"

const Wrapper = styled.div`

.highlights{
    min-height:var(--app-height);
    display:flex;
    justify-content: center;
    align-items:center;
}
@media(max-width:1024px){
    h1{font-size:12vw};
}

.wecreate,
.wecreate2,
.wecreate3{
    position:absolute;
    top:0;
    width: 100vw;
}

.flux{
    height:100vh;
}
`


const StudioWrapper = styled.div`

div{
    z-index: -1;
}
`



// markup
const HighlightsPage = () => {

    return (
        <>
            <div>
                <Layout>
                    <Seo title="Highlights - Vertual" />
                    <Hover type="">
                        <main data-scroll data-scroll-id="update">
                            <ArrowDown />
                            <Wrapper>
                                <div className="kaleido">
                                    <VideoBackground video={"videoHighlights"} />
                                </div>
                                <div className="highlights">
                                    <AnimatedText tag="h1-main">Highlights</AnimatedText>
                                </div>
                                <div className="studio" data-scroll data-scroll-id="update">
                                    <StudioWrapper>
                                        <Project name={"insightsproject"} />
                                    </StudioWrapper>
                                </div>
                                <div className="studio">
                                    <StudioWrapper>
                                        <Project name={"romanproject"} />
                                    </StudioWrapper>
                                </div>
                                {/* <div className="infinite">
                                    <InfiniteText />
                                </div> */}
                                <Footer page="contact" />
                            </Wrapper>
                        </main>
                    </Hover>
                </Layout>
            </div>
        </>
    )
}

export default HighlightsPage


