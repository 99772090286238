import React, { useEffect, useRef, useContext } from "react"
import styled, { keyframes } from 'styled-components'
import { gsap } from "gsap";
import ScrollContext from '../context/scrollContext'
import Hover from "./hover";
import { Link } from "gatsby";
import Image from "./image";

const Strip = styled.div`
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          .highlights{
              position:absolute;
          }
`

const SuperText = styled.h2`
    position: absolute;
    font-size: 7rem;
    letter-spacing: 0.1rem;
    color:#fff;
    text-align:center;
    text-transform:uppercase;
    z-index: 20;

div{
    cursor:pointer;
}

@media (max-width:1024px){
    font-size: 12vw;
}

@media (max-width:512px){
    font-size: 10vw;
}

a{
    font-family:"montserrat", sans-serif;
}

h2{
    text-transform: capitalize;
    letter-spacing: 0;
}

.imageContainer {
    position:absolute;
}

`;

// Create the keyframes
const zoominoutsinglefeatured = keyframes`
  0% {
      transform: scale(1,1);
  }
  50% {
      transform: scale(1.2,1.2);
  }
  100% {
      transform: scale(1,1);
  }
`;

const Images = styled.div`
    justify-content: center;
    display: flex;
    align-items: center;

    .imageWrapper{
    overflow: hidden;
    display: inline-block;
    width: 100vw;
    height: 100vh;
}

.imageWrapper:first-child{

position: absolute;
    z-index: 2;

}
.imageWrapper:nth-child(2){

position: absolute;
    z-index: 1;
}


.imageWrapper:nth-child(3){

position: absolute;

    z-index: 3;
}

.imageWrapper:nth-child(4){
position: absolute;
    z-index: 4;
}

.gatsby-image-wrapper{
    overflow: visible!important;
}

.imageContainer img {
animation: ${zoominoutsinglefeatured} 30s infinite ;
height: var(--app-height);
}

`;


const Project = (props) => {
    const ref = useRef(null);
    const scrollContext = useContext(ScrollContext);
    const element = ref.current;

    const videoList = {
        insightsproject: {
            text: "Valentino Insights",
            slug: "valentino-insights"
        },
        virtualevents: {
            text: "Virtual Events",
            slug: "virtual-events"
        },
        romanproject: {
            text: "Valentino Insights",
            subtitle: "Roman Palazzo Art Residency",
            slug: "valentino-insights-roman-palazzo-art-residency"
        }
    }

    useEffect(() => {
        if (scrollContext.scroll) {

            var tl = gsap.timeline({
                scrollTrigger: {
                    toggleActions: 'play none none none',
                    trigger: element.querySelector(".strip"),
                    scrub: true,
                    start: "top top",
                    end: "+=150%",
                    pin: true,
                }
            });

            if (props.start) {
                tl.to(element.querySelector(".highlights"), {
                    autoAlpha: 0,
                    duration: 1
                }, 0)
                    .fromTo(element.querySelector(".insights"), {
                        autoAlpha: 0,
                    }, {
                        autoAlpha: 1,
                        duration: 1
                    }, 1)
                    .fromTo(element.querySelector(".imageContainer"), {
                        y: "800%",
                    }, {
                        y: 0,
                        duration: 4
                    }, 1)
                    .fromTo(element.querySelector(".superWrapper"), {
                        scale: .5,
                    }, {
                        scale: 1,
                        duration: 4
                    }, 5)
                    .to(element.querySelector(".superWrapper"), {
                        delay: 4,
                        scale: .5,
                        duration: 2
                    }, 10)
            }
            else {
                tl.fromTo(element.querySelector(".insights"), {
                    autoAlpha: 0,
                }, {
                    autoAlpha: 1,
                    duration: 1
                }, 0)
                    .fromTo(element.querySelector(".imageContainer"), {
                        y: "800%",
                    }, {
                        y: 0,
                        duration: 4
                    }, 0)
                    .fromTo(element.querySelector(".superWrapper"), {
                        scale: .5,
                    }, {
                        scale: 1,
                        duration: 4
                    }, 4)
                    .to(element.querySelector(".superWrapper"), {
                        delay: 4,
                        scale: .5,
                        duration: 2
                    }, 9)
            }
        };


    }, [scrollContext]);

    const highlightsText = <div className="highlights"><h1>Highlights</h1></div>

    return <div ref={ref} style={{
        zIndex: "100",
        position: "relative"
    }}>



        <Strip className="strip">

            {props.start && highlightsText}
            <Hover type="projects">
                <Images className="superWrapper">
                    <div className="imageWrapper"><div className="imageContainer"><Link to={"/" + videoList[props.name].slug}> <Image filename={props.name + ".jpg"} alt={props.name} /></Link></div></div>
                </Images>
            </Hover>

            <SuperText>
                <Link to={"/" + videoList[props.name].slug}>
                    <div className="insights">
                        <Hover type="projects">
                            {videoList[props.name].text}
                        </Hover>
                        <Hover type="projects">
                            <h2>{videoList[props.name].subtitle}</h2>
                        </Hover>

                    </div>
                </Link>
            </SuperText>



        </Strip>

    </div>
};

export default Project;