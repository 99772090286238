import React from "react"
import styled from 'styled-components';
import VideoHomepage1 from "../assets/homepage1.mp4"
import VideoServices from "../assets/services.mp4"
import VideoAbout1 from "../assets/about1.mp4"
import VideoHighlights from "../assets/highlights.mp4"
// import PosterHomepage1 from "../images/poster/homepage1.jpg";
// import PosterAbout1 from "../images/poster/about1.jpg"
// import PosterServices from "../images/poster/services.jpg"
// import PosterHighlights from "../images/poster/highlights.jpg";

const VideoWrapper = styled.div`
video {
    object-fit: cover;
    width: 100vw;
    height: var(--app-height);
    position: fixed;
    top: 0;
    left: 0;
    object-position: top;
  }
`
const VideoBackground = (props) => {

    let video = null;
    switch (props.video) {
        case "videoHomepage1": video = VideoHomepage1;
            // poster = PosterHomepage1;
            break;
        case "videoAbout1": video = VideoAbout1;
            // poster = PosterAbout1;
            break;
        case "videoServices": video = VideoServices;
            // poster = PosterServices;
            break;
        case "videoHighlights": video = VideoHighlights;
            // poster = PosterHighlights;
            break;

    }
    return <div className="vid">
        <VideoWrapper>
            <video src={video} autoPlay loop muted playsInline></video>
        </VideoWrapper>
    </div>
};

export default VideoBackground;